import type { FC } from 'react';
import { POLICIES } from '../../../constants';
import { showModalsStore } from '../../../store';
import type { Props } from './FAQ.types';
import { useTranslations } from '../../../i18n/utils';
import { FaqSvg } from './images/faq';
import styles from './faq.module.scss';

export const FAQ: FC<Props> = ({ lang }) => {
  const t = useTranslations(lang);

  const handleClick = () => {
    showModalsStore.set([POLICIES.FAQ]);
  };

  return (
    <button className={styles.faq} type='button' onClick={handleClick}>
      <FaqSvg />
      {t('header.links.4')}
    </button>
  );
};
